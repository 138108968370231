import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CustomMatIconComponent } from '@components/custom-mat-icon/custom-mat-icon.component';
import { CloseCustomBottomSheetDirective } from '@directives/close-custom-bottomsheet/close-custom-bottomsheet.directive';
import { NavigateForwardToDirective } from '@directives/navigate-to/navigate-to.directive';
import { IonButtons, IonHeader, IonToolbar } from '@ionic/angular/standalone';
import { APP_ROUTES } from 'src/app/routes';

@Component({
  selector: 'app-main-menu-dialog',
  standalone: true,
  imports: [
    IonButtons,
    IonToolbar,
    IonHeader,
    MatIconModule,
    CloseCustomBottomSheetDirective,
    CustomMatIconComponent,
    NavigateForwardToDirective,
  ],
  templateUrl: './main-menu-dialog.component.html',
  styles: `
    .main-menu-btn {
      @apply w-32 h-32 flex flex-col items-center justify-center gap-2 bg-black text-primary rounded-3xl;
    }
  `,
})
export default class MainMenuDialogComponent {
  appRoutesEnum = APP_ROUTES;
}
