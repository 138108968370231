import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '@services/auth/auth.service';

export const AuthInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const cloneReq = req.clone({
    headers: req.headers.set('Authorization', `Bearer ${authService.accessToken}`),
  });
  return next(cloneReq);
};
