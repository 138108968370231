import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '@services/auth/auth.service';
import { catchError, throwError } from 'rxjs';

enum ErrorCodes {
  Unauthorized = 401,
}

type ErrorOptions = { [key in ErrorCodes]?: () => void };

export const ErrorReqInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const clone = req.clone();
  const options: ErrorOptions = {
    [ErrorCodes.Unauthorized]: () => authService.logout(),
  };

  return next(clone).pipe(
    catchError((error: HttpErrorResponse) => {
      options[error.status as ErrorCodes]?.();

      return throwError(() => error);
    })
  );
};
