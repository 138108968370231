<ion-header class="ion-no-border">
  <ion-toolbar mode="ios">
    <ion-buttons slot="end">
      <mat-icon appCloseCustomBottomSheet> close </mat-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<main class="w-full h-screen grid grid-cols-2 auto-rows-max gap-x-16 gap-y-11 px-8 pt-3">
  <!-- TODO: Replace Home Path for Profile Path when Profile Page is ready -->
  <div class="button-container justify-center flex">
    <button type="button" class="main-menu-btn" [navigateForwardTo]="appRoutesEnum.HOME" appCloseCustomBottomSheet>
      <app-custom-mat-icon iconName="main-menu-profile-icon" icon="CustomProfileIcon" [buttonSize]="45" />
      <span class="caption-2-medium"> Profile </span>
    </button>
  </div>

  <div class="button-container justify-center flex">
    <button type="button" class="main-menu-btn" [navigateForwardTo]="appRoutesEnum.PAYMENT" appCloseCustomBottomSheet>
      <app-custom-mat-icon iconName="main-menu-payments-icon" icon="CustomDollarIcon" [buttonSize]="45" />
      <span class="caption-2-medium"> Payments </span>
    </button>
  </div>

  <div class="button-container justify-center flex">
    <button type="button" class="main-menu-btn" [navigateForwardTo]="appRoutesEnum.REVIEWS" appCloseCustomBottomSheet>
      <app-custom-mat-icon iconName="main-menu-reviews-icon" icon="CustomStarOutlineIcon" [buttonSize]="45" />
      <span class="caption-2-medium"> Reviews </span>
    </button>
  </div>

  <div class="button-container justify-center flex">
    <button type="button" class="main-menu-btn" [navigateForwardTo]="appRoutesEnum.REFERRALS" appCloseCustomBottomSheet>
      <app-custom-mat-icon iconName="main-menu-referrals-icon" icon="CustomReferralIcon" [buttonSize]="45" />
      <span class="caption-2-medium"> Referrals </span>
    </button>
  </div>

  <div class="button-container justify-center flex">
    <button type="button" class="main-menu-btn" [navigateForwardTo]="appRoutesEnum.SUPPORT" appCloseCustomBottomSheet>
      <app-custom-mat-icon iconName="main-menu-support-icon" icon="CustomHelpCircle" [buttonSize]="45" />
      <span class="caption-2-medium"> Support </span>
    </button>
  </div>
</main>
