import { KeepAwake } from '@capacitor-community/keep-awake';
import { StatusBar } from '@capacitor/status-bar';
import ApplicationInfoService from '@services/application-info/application-info.service';
import { AuthService } from '@services/auth/auth.service';
import BackButtonService from '@services/back-button/back-button.service';
import { UserInfoService } from '@services/user-info/user-info.service';
import OneSignal from 'onesignal-cordova-plugin';

export const initializeUser = (authService: AuthService, userInfoService: UserInfoService): (() => Promise<void>) => {
  return async (): Promise<void> => {
    if (!authService.accessToken) return;
    await userInfoService.fetchCurrentUserInfo();
  };
};

export const initAppPlugins = (backButtonService: BackButtonService, appInfoService: ApplicationInfoService): (() => Promise<void>) => {
  return async (): Promise<void> => {
    if (!appInfoService.isApp) return;
    OneSignal.initialize('96c33e91-338d-4dbe-9742-f35e8d59d4a4');
    StatusBar.setBackgroundColor({ color: '#080810' });
    KeepAwake.keepAwake();
    backButtonService.initBackButtonListener();
  };
};
