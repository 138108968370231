import { Directive, HostListener, Input } from '@angular/core';
import { NavController } from '@ionic/angular'; // Make sure you have ionic/angular imported

@Directive({
  selector: '[navigateForwardTo]',
  standalone: true,
})
export class NavigateForwardToDirective {
  @Input() navigateForwardTo = '';

  constructor(private navCtrl: NavController) {}

  @HostListener('click')
  onClick() {
    this.navCtrl.navigateForward(this.navigateForwardTo);
  }
}
