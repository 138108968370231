import { Component } from '@angular/core';
import { RouterLinkActive } from '@angular/router';
import { CustomMatIconComponent } from '@components/custom-mat-icon/custom-mat-icon.component';
import { NavigateForwardToDirective } from '@directives/navigate-to/navigate-to.directive';
import { ModalController } from '@ionic/angular/standalone';
import { APP_ROUTES } from 'src/app/routes';
import MainMenuDialogComponent from './components/main-menu-dialog.component';

@Component({
  selector: 'app-main-action-sheet-menu',
  standalone: true,
  imports: [RouterLinkActive, CustomMatIconComponent, NavigateForwardToDirective],
  templateUrl: './main-action-sheet-menu.component.html',
  styleUrls: ['./main-action-sheet-menu.component.scss'],
})
export class MainActionSheetMenuComponent {
  appRoutes = APP_ROUTES;

  constructor(private readonly modalCtrl: ModalController) {}

  async openMainMenuDialog(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: MainMenuDialogComponent,
      cssClass: ['custom-blur-modal', 'fast-fade-in'],
      mode: 'ios',
      animated: false,
    });
    modal.present();
  }
}
