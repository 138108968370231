import { Injectable } from '@angular/core';
import { ModalController, NavController, Platform } from '@ionic/angular/standalone';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export default class BackButtonService {
  backButton$?: Subscription;
  onNextClickClose = false;

  constructor(
    private readonly platform: Platform,
    private readonly modalController: ModalController,
    private readonly navController: NavController
  ) {}

  initBackButtonListener() {
    this.backButton$ = this.platform.backButton.subscribeWithPriority(999998, async () => {
      const isModalOpened = await this.modalController.getTop();
      if (isModalOpened) {
        this.modalController.dismiss();
        return;
      }
      this.navController.pop();
    });
  }

  removeListener() {
    this.backButton$?.unsubscribe();
  }
}
