import { bootstrapApplication } from '@angular/platform-browser';
import AppComponent from './app/app.component';
import { register } from 'swiper/element/bundle';
import { initSentry } from './sentry';
import providers from './app/providers';

initSentry();
register();

bootstrapApplication(AppComponent, {
  providers,
}).catch(err => console.log(err));
